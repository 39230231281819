<template>
  <div class="footer py-1">
    <span class="mx-5">
      LIZI ® {{ $t('footer.terms_1') }}
      <a :href="urlTandC" target="_blank">{{ $t('footer.terms_2') }}</a>
    </span>
  </div>
</template>
<script>
import globals from '../../utils/globals'

export default {
  name: 'AuthFooter',
  data () {
    return {
      urlTandC: globals.urlTermsAndConditions
    }
  }
}
</script>

<style scoped>
  a {
    color: black;
  }

  a:hover {
    color: darkslategrey !important;
  }

  .footer {
    background-color: #02b2dff6;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
    text-align: center;
  }

  .footer span {
    font-size: 13px;
    margin: 0px 10px;
    color: black;
  }
</style>

<template>
  <div class="auth-container">
    <AuthCard>
      <router-view />
    </AuthCard>
    <AuthFooter />
  </div>
</template>
<script>

import AuthFooter from '@/components/footer/AuthFooter'
import AuthCard from '@/components/containers/AuthCard'

export default {
  name: 'AuthTemplate',
  components: {
    AuthFooter,
    AuthCard
  },
  data () {
    return {
      cssProps: {

      }
    }
  }
}
</script>

<style scoped>
  body,html {
    height: 100%
  }

  .auth-container {
    background-color: #00ccff99;
    height: 100%;
    width: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>

<template>
  <div class="ui cards card card-container">
    <div class="align-items-center justify-content-center">
      <img class="logo" src="@/assets/img/logo_lizi.png" @click="$router.push('/auth')" />
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthCard'
}
</script>

<style scoped>
  .card-container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .card-container .card {
    box-shadow: 0 1px 4px #181c54 !important;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    background-color: #fff;
    border-radius: 0.75rem;
    padding: 3rem !important;
    width: 342px !important;
    max-width: 342px !important;
    height: 530px !important;
    max-height: 530px !important;
  }

  .card-container.card {
    box-shadow: 0 1px 4px rgb(24 28 33 / 1%) !important;
    position: relative !important;
    display: -ms-flexbox !important;
    display: flex !important;
    background-color: #fff !important;
    border-radius: 0.75rem !important;
    padding: 3rem !important;
    width: 342px !important;
    max-width: 342px !important;
    height: 530px !important;
    max-height: 530px !important;
  }

  .logo {
    max-height: 150px !important;
    max-width: 150px !important;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
  }
</style>
